<template>
  <div class="course-arrange">
    <div v-for="item in list" :key="item.id">
      <p>{{item.chapterName}}</p>
      <a-button @click="videoUrl(item.videoUrl,item.id)">立即观看</a-button>
    </div>
  </div>
</template>

<script>
import { courseSignUp } from "@/api/activity";
export default {
  props:['list','id'],
  methods:{
    videoUrl(url,id){
      let data = {
        chapterId:id,
        courseId:this.id
      }
      courseSignUp(data).then((res)=>{})
      window.open(`${url}`,'_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.course-arrange{
  padding: 0 24px;
  >div{
    height: 88px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    &:last-child{
      border-bottom: 0;
    }
    >span{
      color: #EA0B06;
      width: 136px;
    }
    >p{
      flex: 1;
      margin-right: 24px;
    }
  }
}
</style>
