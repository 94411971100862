<template>
  <div class="home-course-info">
    <div class="home-course-info-head">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><router-link to="/activity">培训活动</router-link> </a-breadcrumb-item>
        <a-breadcrumb-item>课程详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="home-course-info-box">
      <div class="home-course-info">
        <p>{{courseDetail.courseName}}</p>
        <div>
          <span>主讲人：{{courseDetail.coursePresentecoursePresenter}}</span>
          <span>主讲单位：{{courseDetail.courseCompanyName}}</span>
        </div>
        <span>{{courseDetail.summary}}</span>
      </div>
      <span><v-img :attachment-id="courseDetail.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')" /></span>
      <share :name="courseDetail.courseName" :val="courseDetail"/>
    </div>
    <div class="home-course-info-content-box">
      <div class="home-course-info-content-tab">
        <span :class="{ active: courseInfoTab == 1 }" @click="courseInfoTab = 1"
        >课程介绍</span
        >
        <span :class="{ active: courseInfoTab == 2 }" @click="courseInfoTab = 2"
        >课程安排({{courseDetail.crsCourseChapters?courseDetail.crsCourseChapters.length:''}})</span
        >
      </div>
      <course-desc v-if="courseInfoTab == 1" :desc="courseDetail.description"/>
      <course-arrange v-if="courseInfoTab == 2" :id="courseDetail.id" :vodStatus="courseDetail.vodStatus" :list="courseDetail.crsCourseChapters" :acticityIds="courseDetail.crsCourseActivityIds"/>
    </div>
    <div class="home-course-recommend">
      <p>更多推荐</p>
      <div>
        <div v-for="item in courseList" :key="item.id" @click="courseInfoBtn(item.id,item.vodStatus,item.crsCourseActivityIds)">
          <v-img :attachment-id="item.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')" />
          <p>{{item.courseName}}</p>
          <span>共{{item.chaptersTotal?item.chaptersTotal:0}}节课</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import share from "./share.vue";
import courseDesc from "./components/courseDesc.vue";
import courseArrange from "./components/courseArrange.vue";
import { getCourseInfo,getCourseList,verificationEnroll } from "@/api/activity";
import VImg from "@/components/VImg";
import {mapGetters} from "vuex";
export default {
  components: {
    share,
    courseDesc,
    courseArrange,
    VImg
  },
  data() {
    return {
      courseInfoTab: 1,
      courseDetail: "",
      courseList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    id: function () {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.courseInfo();
    this.loadGetCourseList()
  },
  methods: {
    // 详情
    courseInfo() {
      getCourseInfo(this.id).then((res) => {
        this.courseDetail = res;
      });
    },
    // 列表
    loadGetCourseList(){
      let page = {
        current: 1,
        size: 4,
      }
      getCourseList(page,{}).then((res)=>{
        this.courseList = res.records
      })
    },
    courseInfoBtn(id, vodStatus, activityIds) {
      if (vodStatus) {
        //有配置点播权限
        if (!this.userInfo) {
          this.$message.warning("您还未登录！");
          this.$router.push('/login');
          return;
        }
        //判断是否报名课程配置的活动
        if (activityIds && this.userInfo.phone) {
          verificationEnroll(activityIds).then((res) => {
            if (!res) {
              this.$message.warning("很抱歉，该课程您无权查看~");
              return;
            } else {
              this.$router.push({
                path: "/activity/courseInfo",
                query: { id },
              });
              this.courseInfo();
            }
          })
        }
      } else {
        this.$router.push({
          path: "/activity/courseInfo",
          query: { id },
        });
        this.courseInfo();
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.home-course-info {
  width: 1280px;
  margin: 0 auto;
  padding: 24px 0 56px;
  .home-course-info-head {
    margin-bottom: 24px;
  }
  .home-course-info-box {
    background: #fff;
    display: flex;
    position: relative;
    margin-bottom: 24px;
    .home-course-info {
      flex: 1;
      padding: 32px 40px;
      > p {
        line-height: 27px;
        font-size: 24px;
        margin-bottom: 20px;
      }
      > div {
        font-size: 12px;
        display: flex;
        color: #8c8c8c;
        margin-bottom: 24px;
        > span {
          line-height: 11px;
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      > span {
        width: 600px;
        display: block;
        line-height: 22px;
        max-height: 66px;
        overflow: hidden;
        text-align: justify;
        color: #595959;
      }
    }
    > span {
      width: 398px;
      height: 224px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .home-course-info-content-box {
    background: #fff;
    margin-bottom: 24px;
    .home-course-info-content-tab {
      padding: 0 24px;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      > span {
        height: 55px;
        line-height: 55px;
        border-bottom: 2px solid transparent;
        margin-right: 40px;
        cursor: pointer;
        font-size: 16px;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          border-color: #EA0B06;
        }
      }
    }
  }
  .home-course-recommend {
    background: #fff;
    > p {
      height: 56px;
      border-bottom: 1px solid #f0f0f0;
      line-height: 56px;
      padding: 0 16px;
      font-size: 16px;
    }
    > div {
      padding: 24px;
      display: flex;
      > div {
        width: 290px;
        margin-right: 24px;
        cursor: pointer;
        &:nth-child(4n) {
          margin-right: 0;
        }
        > img {
          width: 100%;
          height: 160px;
          display: block;
        }
        > p {
          line-height: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 16px 0 24px;
        }
        > span {
          display: block;
          font-size: 12px;
          line-height: 11px;
          color: #bfbfbf;
        }
      }
    }
  }
}
</style>
<style>
.home-policy-info-head .ant-breadcrumb a:hover {
  color: #EA0B06;
}
#crs-lock {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 5%;
  top: 75%;
}
</style>
