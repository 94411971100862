<template>
  <div class="course-desc" v-html="desc"></div>
</template>

<script>
export default {
  props:['desc']
}
</script>

<style lang="scss" scoped>
.course-desc{
  padding: 32px 24px;
}
</style>